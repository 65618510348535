<template>

  <div style="height: inherit">
    <div class="d-flex justify-content-between align-items-center" />
    <!-- Main Area -->
    <section class="chat-app-window">

      <!-- Start Chat Logo -->

      <!-- Chat Content -->
      <div
        v-if="ticketData"
        class="active-chat"
      >
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">

            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">

              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1" />
              <b-link

                :to="{name:'support'}"
              > <feather-icon
                icon="ArrowLeftIcon"
                size="30"
                class="mr-50"
              />
              </b-link>
              <h6 class="mb-0">
                {{ ticketData.title }}
              </h6>
            </div>

          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refMessages"
          :settings="perfectScrollbarSettings"
          style="height:600px"
          class="user-chats scroll-area"
        >
          <message
            :messages="messages"
          />
        </vue-perfect-scrollbar>
        <validation-observer

          ref="fromMessage"
        >

          <validation-provider
            #default="{ errors }"
            :name="$t('Message')"
            rules="required|min:2"
          >

            <b-form-textarea
              id="textarea-default"
              v-model="messageText"
              placeholder="Enter a new message"
              rows="3"
              autofocus
              :state="errors.length > 0 ? false:null"
            />
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>

          </validation-provider>

        </validation-observer>

        <b-button
          class="mt-1"
          variant="primary"
          block
          @click="addMessage"
        >
          Send Message
        </b-button>
        <!-- Message Input -->

      </div>
    </section>

  </div>
</template>

<script>
import {
  BFormTextarea, BButton, BFormInvalidFeedback, BLink,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import supportStoreModule from './supportStoreModule'
import message from './Message.vue'

export default {
  components: {

    // Form Validation

    BFormTextarea,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    VuePerfectScrollbar,
    message,
    BLink,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      required,
      alphaNum,
      email,
      isSuccess: false,
      errorData: null,
      ticketData: null,
      messages: [],
      messageText: '',
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
    }
  },
  created() {
    this.fetchTicketData()
  },
  methods: {
    scrollToBottomInMessages() {
      const scrollEl = this.$refs.refMessages.$el
      scrollEl.scrollTop = scrollEl.scrollHeight
    },
    fetchTicketData() {
      store
        .dispatch('app-support/fetchSingleTicket', { id: this.$router.currentRoute.params.id })
        .then(response => {
          const { data } = response.data
          this.ticketData = data
          this.fetchMessages()
        })
        .catch(() => {

        })
    },
    fetchMessages() {
      store
        .dispatch('app-support/fetchMessages', { ticket_id: this.ticketData.id })
        .then(response => {
          const { data } = response.data
          this.messages = data
        })
        .catch(() => {

        })
    },
    addMessage() {
      this.$refs.fromMessage.validate().then(success => {
        if (success) {
          store.dispatch('app-support/addMessage', { ticket_id: this.ticketData.id, message: this.messageText })
            // eslint-disable-next-line no-unused-vars
            .then(response => {
              this.messages.push(response.data.data)
              this.$nextTick(() => { this.scrollToBottomInMessages() })
              this.messageText = ''
            })
            .catch(error => {
              if (error.response.status === 422) {
                this.errorData = error.response.data
              }
            })
        }
      })
    },

  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-support'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, supportStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    return {
    }
  },
}
</script>
<style lang="scss" scoped>

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
