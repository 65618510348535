<template>
  <div class="chats">
    <div
      v-for="(message) in messages"
      :key="message.id"
      class="chat"
      :class="{'chat-left': message.last_sender==0}"
    >
      <div class="chat-avatar">
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="transparent"
        />
      </div>
      <div class="chat-body">
        <div

          class="chat-content"
        >
          <p>{{ message.message }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },
  props: {
    messages: {
      type: Array,
      required: true,
    },
  },

}
</script>

<style>

</style>
